
import { Component, Vue } from 'vue-property-decorator';
import { FarmStore, JwtStore, MapStore } from '@/store/modules';

@Component({
  meta: {
    title: 'FruitMaps'
  }
})
export default class App extends Vue {
  async beforeCreate() {
    MapStore.getGeoserverConfig();
    // eslint-disable-next-line
    self.addEventListener('subscribe', function(e: any) {
      if (!e.detail.data.user_agent) {
        // eslint-disable-next-line
        e.detail.data.user_agent = window.navigator.userAgent;
      }
      JwtStore.subscribe(e.detail.data);
    });
    await JwtStore.refreshAccessToken();
    if (JwtStore.isLoggedIn) {
      await FarmStore.getMyDefaultFarm();
    } else {
      await FarmStore.setFetchingDefaultFarm(false);
    }
    if (process.env.VUE_APP_DARK_MODE) {
      this.$q.dark.set(true);
    } else {
      this.$q.dark.set(false);
    }
  }
  created() {
    // BlockStore.getAllMyBlocksWithGeom();
  }
}
